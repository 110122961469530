<template>
  <div class="violation">
    <div class="violation-info">
      <h4 class="title">行为标准化检测系统检测报告</h4>
      <van-icon color="#f00" name="warning" class="warn"> 行为标准化检测到你有一条{{ detail.type | formatType }}异常</van-icon>
      <van-divider>{{ detail.type | formatType }}</van-divider>
      <van-grid :border="false" :column-num="2">
        <van-grid-item @click="prevImg(detail.beginImg)">
          <van-image :src="detail.beginImg" />
          <span class="date">{{ detail.startTime }}</span>
        </van-grid-item>
        <van-grid-item @click="prevImg(detail.endImg)">
          <van-image :src="detail.endImg" />
          <span class="date">{{ detail.endTime }}</span>
        </van-grid-item>
      </van-grid>
      <div class="appeal-detail" v-if="[0,1,2].includes(appealDetail.appealStatus)">
        <div class="detail-title">申诉详情</div>
        <div class="detail-row">
          <div class="row-label">申诉内容：</div>
          <div class="row-value">{{appealDetail.appealResson}}</div>
        </div>
        <div class="detail-row">
          <div class="row-label">申诉状态：</div>
          <div class="row-value">{{ appealDetail.appealStatus | formatStatus }}</div>
        </div>
        <div class="detail-row" v-if="appealDetail.appealStatus!==0">
          <div class="row-label">审批意见：</div>
          <div class="row-value">{{appealDetail.approveOpinion}}</div>
        </div>
      </div>
    </div>
    <van-button v-if="![0,1,2].includes(appealDetail.appealStatus)" type="info" round block @click="appealPopupVisible=true">申诉</van-button>
    <van-popup
      class="appeal-popup"
      round
      v-model="appealPopupVisible"
      position="center"
      @close="appealPopupClose"
    >
      <van-form
        class="form"
        ref="appealForm"
        :show-error-message="false"
        @submit="formSubmit"
      >
        <van-field
          size="large"
          v-model="appealForm.appealReason"
          name="appealReason"
          required
          type="textarea"
          label="申诉内容"
          placeholder="请输入申诉内容"
          :rules="[{ required: true, message: '请输入申诉内容' }]"
        />

        <div class="submit">
          <van-button
            class="operate-btn"
            block
            size="small"
            round
            color="#3b95fc"
            native-type="submit"
            >确定</van-button
          >
          <van-button
            class="operate-btn"
            @click="closeAppealPopup"
            block
            size="small"
            round
            >取消</van-button
          >
        </div>
      </van-form>
    </van-popup>
  </div>
</template>

<script>
import { getDataByTypeId,save,detail } from "@/api/desktop";
import { getQueryVariable } from "@/common/utils";
import { Toast,Icon, Image,Grid,Button, GridItem ,Divider,ImagePreview,Popup,Form,Field  } from "vant";
import { mapState } from "vuex";
export default {
  name: "violation",
  components: {
    [Icon.name]: Icon,
    [Image.name]: Image,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [Divider.name]: Divider,
    [Button.name]:Button,
    [Popup.name]:Popup,
    [Form.name]:Form,
    [Field.name]:Field,
    [Toast.name]:Toast
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo,
    }),
  },
  data() {
    return {
      detail: {},
      appealPopupVisible:false,
      appealForm:{
        appealReason:''
      },
      appealDetail:{}
    };
  },
  filters: {
    formatType(type) {
      switch (type) {
        case 1:
          return '玩手机'
        case 2:
          return '离岗脱岗'
        case 3:
          return '桌面物品摆放'
        default:
          return '未知'
      }
    },
    formatStatus(status) {
      switch (status) {
        case 0:
          return '申诉审核中'
        case 1:
          return '申诉成功'
        case 2:
          return '申诉失败'
        default:
          return '未知'
      }
    }
  },
  mounted() {
    this.getStatus();
    getDataByTypeId({
      id: getQueryVariable('state'),
    }).then(res => {
      this.detail = res.data
    })
  },
  methods: {
    getStatus(){
      detail({recordId:getQueryVariable('state')}).then(res=>{
        this.appealDetail = res.data.data;
      })
    },
    prevImg(img){
      ImagePreview([img]);
    },
    appealPopupClose(){
      this.appealForm.appealReason = "";
      this.$refs.appealForm.resetValidation("appealReason");
    },
    closeAppealPopup() {
      this.appealPopupVisible = false;
    },
    async formSubmit() {
      let { appealReason } = this.appealForm;
      const params = new FormData();
      params.append('id',getQueryVariable('state'));
      params.append('appealReason',appealReason);
      let res = await save(params);
      this.getStatus();
      if(res.data.success){
        Toast('申诉提交成功');
      }else{
        Toast(res.data.msg)
      }
      this.closeAppealPopup();
    },
  },
};
</script>

<style lang="scss" scoped>
.violation {
  padding: 20px;
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .title {
    font-size: 16px;

  }

  .warn {
    font-size: 14px;
  }
  .date {
    font-size: 14px;
    margin-top: 10px;
  }
}
.appeal-detail{
  .detail-title{
    padding-top: 12px;
    font-size: 16px;
    font-weight: bold;
    opacity: 0.8;
  }
  .detail-row{
    display: flex;
    font-size: 14px;
    margin-top: 16px;
    .row-label{
      white-space: nowrap;
      opacity: 0.6;
    }
    .row-value{
      word-break: break-all;
    }
  }
}
.appeal-popup {
  width: 80%;
  padding: 10px;

  .submit {
    margin-top: 10px;
    display: flex;
    justify-content: space-around;
    .operate-btn {
      width: 40%;
    }
  }
}
</style>