import request from '../common/request';

// 违规查询
export const getDataByTypeId = (params) => request({
  url: '/api/desktop/deskItemDetectRecord/getDataByTypeId',
  method: 'get',
  params:{
    ...params
  }
});

//违规申诉  
export const save = (data) => request({
  url: '/api/desktop/deskItemDetectRecord/appealSave',
  method: 'post',
  data
});

//违规状态查询  
export const detail = (params) => request({
  url: '/api/desktop/deskItemDetectRecord/appealDetail',
  method: 'get',
  params:{
    ...params
  }
});


